import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Certificates from "./Certificates";
import { Helmet } from "react-helmet";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverActive: "",
    };
  }
  componentDidMount() {
    let url = window.location.href;
    if (url.includes("about-us#partners")) {
      if (window.innerWidth < 640) {
        window.scrollTo(0, 1450);
      } else {
        window.scrollTo(0, 950);
      }
    }
  }
  setData = (param) => {
    this.setState({
      hoverActive: param,
    });
  };
  removeData = () => {
    this.setState({
      hoverActive: "",
    });
  };
  render() {
    return (
      <div className="about-page">
        <Helmet>
          <title>About us</title>
          <meta
            name="description"
            content="Discover the story behind Probacus Engineering Solutions. Established in 2010, we lead in providing premium engineering services, specializing in Manufacturing Execution Systems, process design, and sustainable solutions."
          />
          <meta
            name="keywords"
            content="Engineering Company, Manufacturing Execution Systems, Process Design, Sustainable Solutions"
          />
        </Helmet>
        <Header />
        <section class="hero-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="tilt-area">
                  <div class="background-overlay"></div>
                </div>
                <div className="content">
                  <div class="mini-logo">
                    <img src="./assets/images/logo-min.png" />
                    <div id="text-blob">Probacus</div>
                  </div>
                  <div class="row para">
                    <div class="col-lg-12 m-auto">
                      <h4 className="text-center">About Us</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <secction class="who-we-are">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 m-auto">
                <div class="content">
                  <h4 class="text-center">Who We Are</h4>
                  <p class="text-center mb-0">
                    Probacus Engineering Solutions is a Private Limited company
                    established since 2010. We have two permanent member
                    Directors supported by a battery of expert engineers. <br />{" "}
                    We work from two geographical locations:
                  </p>
                  <p>
                    Registered Office: 39, Chitale Marg, Dhantoli, Nagpur –
                    440012
                    <br />
                    Pune: A1-206, Daffodils Society, Pimple Saudagar, Pune
                    411027
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div class="row mv-wrapper">
              <div class="col-lg-6">
                <div class="single-block">
                  <h4>Mission</h4>
                  <p>
                    To provide our clients with premium quality engineering
                    solutions and consultancy services which are safe, cost
                    effective and environment friendly, by way of using most
                    up-to-date technology and advanced applications.
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single-block">
                  <h4>Vision</h4>
                  <p>
                    To become a premier service provider and consulting company
                    leading engineering and manufacturing to ecologically
                    sustainable growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </secction>
        <section class="proud-partner" id="partners">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div class="white-block">
                  <h1 class="text-center">Proud Partner</h1>
                  <img
                    src="./assets/images/aveva_logo.png"
                    class="logo-partner"
                    alt=""
                  />
                  <p class="param">
                    We put the Cloud, IoT, AI, and virtual reality to work for
                    you in powerful ways. We carefully analyze how the latest
                    innovations can be included in your business without
                    disruption and deliver seamless solutions that enhance your
                    entire asset and operations lifecycle. Our end-to-end
                    industrial portfolio helps you to target business value
                    without sacrificing opportunities.
                  </p>
                  <h6 class="text-center">AVEVA™ PI System™</h6>
                  <p class="param">
                    AVEVA PI System (formerly OSIsoft PI System), is an
                    industry-leading data management solution built specifically
                    to overcome the challenges of industrial environments. With
                    AVEVA PI System, you can collect and store data from any
                    location and source and rapidly extract the insights you
                    need to optimize your business—all in a no-code, flexible
                    environment.
                  </p>

                  <div class="row">
                    <div class="col-lg-6">
                      <h6>AVEVA Process Optimization</h6>
                      <p class="para">
                        Boost Profitability with Real-Time Process Optimization
                        by maximizing your operating profit and improving plant
                        performance with AVEVA Process Optimization. AVEVA
                        Process Optimization uses real-time and off-line process
                        and economic data to guarantee maximum operating profit
                        while satisfying all regulatory requirements
                      </p>
                    </div>
                    <div class="col-lg-6">
                      <h6>AVEVA™ Unified Operations Center for Midstream</h6>
                      <p class="para">
                        Proactively resolve abnormal events for oil and gas
                        pipeline operations management, preventing disruption of
                        production, and integrating with digital safety
                        management systems making operations safer, more
                        sustainable, and efficient through a single
                        pane-of-glass Catch abnormal events in pipeline
                        operations before they disrupt production. By
                        integrating operations data with digital safety
                        management systems in a single dashboard, operators can
                        proactively resolve issues while keeping operations
                        safe, efficient, and sustainable.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="white-block mt-3">
                  <p class="param">
                    Apart from our proud partnership with AVEVA, we have
                    following esteemed global leaders in firewall and networking
                    solution technology as our proud partners for resale and
                    distribution of their products.
                  </p>
                  <a href="https://www.paloaltonetworks.com/" target="_blank">
                    <img
                      src="./assets/images/completedProjects/palo-altp.png"
                      class="logo-partner palo-alt-logo"
                      alt=""
                    />
                  </a>
                  <p class="param" style={{ paddingTop: 16 }}>
                    We are authorized retailers of Palo Alto Next-Generation
                    Firewalls
                  </p>
                  <a href="https://www.fortinet.com/" target="_blank">
                    <img
                      src="./assets/images/completedProjects/fortinet.png"
                      class="logo-partner palo-alt-logo"
                      alt=""
                    />
                  </a>
                  <p class="param" style={{ paddingTop: 16 }}>
                    We are authorized retailers of FORTINET Next-Generation
                    Firewalls.
                  </p>
                  <a href="https://www.cisco.com/" target="_blank">
                    <img
                      src="./assets/images/completedProjects/cisco.png"
                      class="logo-partner"
                      alt=""
                      style={{
                        maxWidth: 210,
                        margin: "35px auto 0 auto !important",
                      }}
                    />
                  </a>
                  <p class="param" style={{ paddingTop: 16 }}>
                    We are authorized retailers of CISCO network switches
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="leadership-section">
          <h6 class="text-center">Directors</h6>
          <h1 class="text-center">Probacus Leadership</h1>
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="single">
                  <div className="profile">
                    <img src="./assets/images/leader/Prasad_Patgaonkar.jpg" />
                  </div>
                  <h3>Prasad Patgaonkar</h3>
                  <h5>(Promoter and Executive Director)</h5>
                  <p>
                    Prasad comes from a process engineering and simulation
                    background for more than 25 years of experience. He has
                    built his expertise in simulation engineering and process
                    design through various project implementations in the field
                    of Real Time Optimization (RTO), Operator Training Simulator
                    (OTS) and Basic/Detailed engineering design. He is
                    passionate about chemical thermodynamics and mathematical
                    modeling.
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single">
                  <div className="profile">
                    <img src="./assets/images/leader/Jaidev_Parmar.jpg" />
                  </div>
                  <h3>Jaidev Parmar</h3>
                  <h5>(Promoter and Executive Director)</h5>
                  <p>
                    Jaidev is both a chemical engineer by education and an avid
                    IT developer in his own unique development experience. He
                    has been working in core software development of process
                    simulation for most of his career spanning for over 25
                    years. He has built many useful addon software tools to
                    integrate simulation software for RTO. Examples of his
                    development include Python interface, Excel addon, custom
                    database management tool with the core simulation software
                    to name a few. His keen interests include modernization and
                    state of the art IT drivers for legacy simulation software.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="completed-projects">
          <div class="background-overlay"></div>
          <div class="content">
            <h5 class="text-center">Experience</h5>
            <h1 class="text-center">Successfully Completed Projects</h1>
            <div class="project-block">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div className="col-lg-12 d-block d-sm-block d-md-block d-lg-none d-xl-none">
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <div class="wpr-acc-icon-box">
                              <span class="wpr-title-icon">
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              ADANI
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              1. PI system implementation and system integration
                              for ADANI-TOTAL LNG Terminal DLTPL (2023).
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 17,
                                  top: 12,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              TOTAL LNG
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. PI system implementation and system
                                integration for ADANI-TOTAL LNG Terminal DLTPL
                                (2023).
                              </p>
                              <p>
                                2. PI system implementation and system
                                integration and MES System for Total LNG
                                (TempaRossa, Italy, 2017),
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 17,
                                  top: 12,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              ARAMCO Yanbu
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Implementation of the PI Vision and PI A/F
                                application at ARAMCO Yanbu refinery during June
                                – September 2023
                              </p>
                              <p>
                                2. Implementation and support to Aveva for RTO
                                solution at at ARAMCO Yanbu refinery during Jan
                                to June – 2022
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFour">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 20,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              ADNOC
                            </button>
                          </h2>
                          <div
                            id="collapseFour"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. What-if Case study tool to work with Aveva’s
                                APO (ROMeo) optimizer simulator for ADNOC Gas
                                plants (ASAB, Bu Hasa, Ruwais) (2022)
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* HPCL */}
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFive">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              HPCL
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Engineering and consultancy services to
                                Hindustan Petroleum Corporation Ltd (HPCL),
                                Mumbai. We have saved more than Rs. 3 Crore over
                                4 years of an end to end consultancy service for
                                purification of contaminated solvent used for
                                purification of food grade hexane.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Arvind envisol  6 */}
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#headingSix"
                              aria-expanded="false"
                              aria-controls="headingSix"
                            >
                              Arvind Envisol
                            </button>
                          </h2>
                          <div
                            id="headingSix"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#headingSix"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Provided Basic Engineering and detailed
                                engineering projects for Arvind Envisol
                                proprietary batch processes.
                              </p>
                              <p>
                                2. Successfully designed and provided BEP for a
                                portable batch processing plant for producing
                                ETP chemicals
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div class="accordion-item">
                          <h2 class="accordion-header" id="headingSix">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#headingSix"
                              aria-expanded="false"
                              aria-controls="headingSix"
                            >
                              Arvind Envisol
                            </button>
                          </h2>
                          <div
                            id="headingSix"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Provided Basic Engineering and detailed
                                engineering projects for Arvind Envisol
                                proprietary batch processes.
                              </p>
                              <p>
                                2. Successfully designed and provided BEP for a
                                portable batch processing plant for producing
                                ETP chemicals
                              </p>
                            </div>
                          </div>
                        </div> */}
                        {/* Safco 7 */}
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#heading7"
                              aria-expanded="false"
                              aria-controls="heading7"
                            >
                              SAFCO
                            </button>
                          </h2>
                          <div
                            id="heading7"
                            class="accordion-collapse collapse"
                            aria-labelledby="heading7"
                            data-bs-parent="#heading7"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Heat Exchanger network and pinch analysis
                                study for SAFCO, Al-Jubail, Saudi Arabia
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Schneider-Electric 8 */}
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#heading8"
                              aria-expanded="false"
                              aria-controls="heading8"
                            >
                              Schneider-Electric
                            </button>
                          </h2>
                          <div
                            id="heading8"
                            class="accordion-collapse collapse"
                            aria-labelledby="heading8"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Implementing IOCL DRYA project for 8
                                refineries in partnership with Shneider-Electric
                                (2019)
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Petronus 9 */}
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#heading9"
                              aria-expanded="false"
                              aria-controls="heading9"
                            >
                              PETRONAS
                            </button>
                          </h2>
                          <div
                            id="heading9"
                            class="accordion-collapse collapse"
                            aria-labelledby="heading9"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Provided Energy Management simulation based
                                Lead support for PETRONAS RAPID project where
                                ROMeo based Energy and Utility Optimisation
                                project was implemented by Accenture, Malaysia
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Curia india  */}
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#heading10"
                              aria-expanded="false"
                              aria-controls="heading10"
                            >
                              Curia India Pvt, Ltd
                            </button>
                          </h2>
                          <div
                            id="heading10"
                            class="accordion-collapse collapse"
                            aria-labelledby="heading10"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Successfully designed and provided BEP for
                                reactors, mechanical thickness calculations and
                                data sheets for batch reactors and pressure
                                vessels.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* Piramal care  */}
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <div class="wpr-acc-icon-box">
                              <span
                                class="wpr-title-icon"
                                style={{
                                  left: 19,
                                  top: 7,
                                }}
                              >
                                <span class="material-icon material-symbols-outlined">
                                  done
                                </span>
                              </span>
                            </div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#heading11"
                              aria-expanded="false"
                              aria-controls="heading11"
                            >
                              Piramal Critical Care
                            </button>
                          </h2>
                          <div
                            id="heading11"
                            class="accordion-collapse collapse"
                            aria-labelledby="heading11"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <p>
                                1. Successfully provided separation process
                                design with number of stages and hydraulic
                                diameter
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(1)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>ADANI</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(2)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>TOTAL LNG</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(3)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>ARAMCO Yanbu</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(4)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>ADNOC</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(5)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>HPCL</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(6)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>Arvind Envisol</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(7)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>SAFCO</h4>
                      </div>
                    </div>
                    {/* <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(8)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>HONEYWELL</h4>
                      </div>
                    </div> */}
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(9)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>Schneider-Electric</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(10)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>PETRONAS</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(11)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>Curia India Pvt. Ltd</h4>
                      </div>
                    </div>
                    <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                      <div
                        class="sub-block"
                        onMouseOver={() => this.setData(12)}
                        onMouseOut={() => this.removeData()}
                      >
                        <h4>Piramal Critical Care</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div className="comp-pro-ver-center">
                    {this.state.hoverActive == "1" && (
                      <div class="project-list">
                        <p>
                          1. PI system implementation and system integration for
                          ADANI-TOTAL LNG Terminal DLTPL (2023).
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "2" && (
                      <div class="project-list">
                        <p>
                          1. PI system implementation and system integration for
                          ADANI-TOTAL LNG Terminal DLTPL (2023).
                        </p>
                        <p>
                          2. PI system implementation and system integration and
                          MES System for Total LNG (TempaRossa, Italy, 2017),
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "3" && (
                      <div class="project-list" style={{ top: 111 }}>
                        <p>
                          1. Implementation of the PI Vision and PI A/F
                          application at ARAMCO Yanbu refinery during June –
                          September 2023
                        </p>
                        <p>
                          2. Implementation and support to Aveva for RTO
                          solution at at ARAMCO Yanbu refinery during Jan to
                          June – 2022
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "4" && (
                      <div class="project-list" style={{ top: 111 }}>
                        <p>
                          1. What-if Case study tool to work with Aveva’s APO
                          (ROMeo) optimizer simulator for ADNOC Gas plants
                          (ASAB, Bu Hasa, Ruwais) (2022)
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "5" && (
                      <div class="project-list" style={{ top: 220 }}>
                        <p>
                          1. Engineering and consultancy services to Hindustan
                          Petroleum Corporation Ltd (HPCL), Mumbai. We have
                          saved more than Rs. 3 Crore over 4 years of an end to
                          end consultancy service for purification of
                          contaminated solvent used for purification of food
                          grade hexane.
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "6" && (
                      <div class="project-list" style={{ top: 220 }}>
                        <p>
                          1. Provided Basic Engineering and detailed engineering
                          projects for Arvind Envisol proprietary batch
                          processes.
                        </p>
                        <p>
                          2. Successfully designed and provided BEP for a
                          portable batch processing plant for producing ETP
                          chemicals
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "7" && (
                      <div class="project-list" style={{ top: 328 }}>
                        <p>
                          1. Heat Exchanger network and pinch analysis study for
                          SAFCO, Al-Jubail, Saudi Arabia
                        </p>
                      </div>
                    )}
                    {/* {this.state.hoverActive == "8" && (
                      <div class="project-list" style={{ top: 328 }}>
                        <p>
                          1. APC Control loop tuning of Ethylene plant in Egypt
                          for HONEYWELL
                        </p>
                      </div>
                    )} */}
                    {this.state.hoverActive == "9" && (
                      <div class="project-list" style={{ top: 328 }}>
                        <p>
                          1. Implementing IOCL DRYA project for 8 refineries in
                          partnership with Shneider-Electric (2019)
                        </p>
                      </div>
                    )}
                    {this.state.hoverActive == "10" && (
                      <div class="project-list" style={{ bottom: 121 }}>
                        <p>
                          1. Provided Energy Management simulation based Lead
                          support for PETRONAS RAPID project where ROMeo based
                          Energy and Utility Optimisation project was
                          implemented by Accenture, Malaysia
                        </p>
                      </div>
                    )}
                    {/* curia india */}
                    {this.state.hoverActive == "11" && (
                      <div class="project-list" style={{ bottom: 121 }}>
                        <p>
                          1. Successfully designed and provided BEP for
                          reactors, mechanical thickness calculations and data
                          sheets for batch reactors and pressure vessels.
                        </p>
                      </div>
                    )}
                    {/* Piramal Critical Care */}
                    {this.state.hoverActive == "12" && (
                      <div class="project-list" style={{ bottom: 20 }}>
                        <p>
                          1. Successfully provided separation process design
                          with number of stages and hydraulic diameter
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="row documents">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                  <Certificates />
                  {/* <img
                    class="w-100"
                    src="./assets/images/documents/AVEVA-Certification-Letter_page-0001.jpg"
                  /> */}
                </div>
                {/* <div class="col-lg-4"> */}
                {/* <img
                    class="w-100"
                    src="./assets/images/documents/DLTPL-Completion_page-0001.jpg"
                  /> */}
                {/* </div> */}
                <div class="col-lg-2"></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
