import React, { Component } from "react";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openStatus: false,
    };
  }
  toggleMenu = () => {
    this.setState({
      openStatus: !this.state.openStatus,
    });
  };
  render() {
    return (
      <div className="header-section">
        <div className="container-fluid px-0" style={{ maxWidth: 1140 }}>
          <div className="row">
            <div className="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6">
              <div className="d-flex">
                <a href="/" class="logo">
                  <img
                    className=""
                    src="./assets/images/logo.png"
                    alt="default-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
              <div
                style={{ float: "right" }}
                className="link-list d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
              >
                <ul>
                  <li
                    className={window.location.pathname == "/" ? "active" : ""}
                  >
                    <a href="/">Home</a>
                  </li>
                  <li
                    className={
                      window.location.pathname == "/about-us" ? "active" : ""
                    }
                  >
                    <a href="/about-us">About Us</a>
                  </li>
                  <li
                    className={
                      window.location.pathname == "/about-us#partners"
                        ? "active"
                        : ""
                    }
                  >
                    <a href="/about-us#partners">Partners</a>
                  </li>
                  <li
                    className={
                      window.location.pathname == "/blog-posts" ||
                      window.location.pathname == "/blog-details"
                        ? "active"
                        : ""
                    }
                  >
                    <a href="/blog-posts">News</a>
                  </li>
                  <li
                    className={
                      window.location.pathname == "/contact" ? "active" : ""
                    }
                  >
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div>
                <div class="menu-icon" onClick={() => this.toggleMenu()}>
                  <img src="./assets/images/header/open-menu-icon.png" />
                </div>
              </div>
              {this.state.openStatus == true && (
                <div className="link-list d-block d-sm-block d-md-block d-lg-none d-xl-none">
                  <ul>
                    <li
                      className={
                        window.location.pathname == "/" ? "active" : ""
                      }
                    >
                      <a href="/">Home</a>
                    </li>
                    <li
                      className={
                        window.location.pathname == "/about-us" ? "active" : ""
                      }
                    >
                      <a href="/about-us">About Us</a>
                    </li>
                    <li
                      className={
                        window.location.pathname == "/about-us#partners"
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/about-us#partners">Partners</a>
                    </li>
                    <li
                      className={
                        window.location.pathname == "/blog-posts" ||
                        window.location.pathname == "/blog-details"
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/blog-posts">News</a>
                    </li>
                    <li
                      className={
                        window.location.pathname == "/contact" ? "active" : ""
                      }
                    >
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
