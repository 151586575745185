import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./app/Home";
import About from "./app/About";
import Contact from "./app/Contact";
import Blog from "./app/Blog";
import BlogDetails from "./app/BlogDetails";
import Certificates from "./app/Certificates";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/blog-posts" exact element={<Blog />} />
          <Route path="/blog-details" exact element={<BlogDetails />} />
          <Route path="/test" exact element={<Certificates />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
