import React, { Component } from "react";
import { message, Spin } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      email: "",
      mobile: "",
      subject: "",
      messageText: "",
      error_name: "",
      error_email: "",
      error_mobile: "",
      error_subject: "",
      error_messageText: "",
    };
  }
  submit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check if the entered email matches the pattern
    const isValid = emailRegex.test(this.state.email);

    const mobileRegex = /^[0-9]{10}$/;
    const isValidMobile = mobileRegex.test(this.state.mobile);

    if (!this.state.name) {
      this.setState({
        error_name: "Name is required.",
      });
      // message.warning("Name is required.");
    } else {
      this.setState({
        error_name: "",
      });
    }

    if (!this.state.email) {
      // message.warning("Email address is required.");
      this.setState({
        error_email: "Email address is required.",
      });
    } else {
      this.setState({
        error_email: "",
      });
    }
    if (isValid == false) {
      // message.warning("Invalid Email address format.");
      this.setState({
        error_email: "Invalid Email address format.",
      });
    } else {
      this.setState({
        error_email: "",
      });
    }
    if (!this.state.mobile) {
      // message.warning("Mobile number is required.");
      this.setState({
        error_mobile: "Mobile number is required.",
      });
    } else {
      this.setState({
        error_mobile: "",
      });
    }
    if (isValidMobile == false) {
      // message.warning("Invalid Mobile number.");
      this.setState({
        error_mobile: "Invalid Mobile number.",
      });
    } else {
      this.setState({
        error_mobile: "",
      });
    }
    if (!this.state.subject) {
      this.setState({
        error_subject: "Subject is required.",
      });
      // message.warning("Subject is required.")
    } else {
      this.setState({
        error_subject: "",
      });
    }
    if (
      !this.state.name ||
      !this.state.email ||
      !this.state.mobile ||
      !this.state.subject ||
      isValidMobile == false ||
      isValid == false
    ) {
      var element = document.getElementById("contact-form");
      element.scrollIntoView({
        behavior: "smooth",
      });
      return;
    } else {
      this.setState({ loading: true });
      let params = {
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        message: this.state.messageText,
        subject: this.state.subject,
      };
      let url = "https://admin.probacus.com/public/api/web/submit-form";
      let headers = {
        "Content-Type": "application/json",
      };
      console.log("params", params);
      axios
        .post(url, params, { headers: headers })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.setState({
              loading: false,
              name: "",
              email: "",
              mobile: "",
              subject: "",
              messageText: "",
              error_name: "",
              error_email: "",
              error_mobile: "",
              error_subject: "",
              error_messageText: "",
            });
            Swal.fire({
              icon: "success",
              title: "Great",
              text: "Thank you for your interest. Your gateway to cutting-edge engineering solutions and unparalleled technical support. Our dedicated team of experts is ready to reach out to you shortly. Your success is our commitment! #EngineeringInnovation #Probacus",
              footer:
                'If there is any issue. You can contact us on <a class="sweetalert-link" href="mailto:info@probacus.com">info@probacus.com</a>',
            });
            // message.success(
            //   "Thank you for contacting us. Our expert will reach you soon."
            // );
          } else {
            this.setState({ loading: false });
            message.warning(response.data.message);
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          message.warning("Something went wromg.Please try again.");
        });
    }
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div class={`form ${this.props.classes}`}>
            <div class="form-group">
              <label for="">Your name *</label>
              <input
                type="text"
                placeholder="Your name here"
                class={
                  this.state.error_name
                    ? "form-control error-field"
                    : "form-control"
                }
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
              {this.state.error_name && (
                <span className="error-text">{this.state.error_name}</span>
              )}
            </div>
            <div class="form-group">
              <label for="">Your email *</label>
              <input
                type="text"
                placeholder="Your email address here"
                class={
                  this.state.error_email
                    ? "form-control error-field"
                    : "form-control"
                }
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              {this.state.error_email && (
                <span className="error-text">{this.state.error_email}</span>
              )}
            </div>
            <div class="form-group">
              <label for="">Mobile Number *</label>
              <input
                type="number"
                placeholder="Enter your mobile number here"
                class={
                  this.state.error_mobile
                    ? "form-control error-field"
                    : "form-control"
                }
                value={this.state.mobile}
                onChange={(e) => this.setState({ mobile: e.target.value })}
              />
              {this.state.error_mobile && (
                <span className="error-text">{this.state.error_mobile}</span>
              )}
            </div>
            <div class="form-group">
              <label for="">Subject *</label>
              <input
                type="text"
                placeholder="Enter your subject here"
                class={
                  this.state.error_subject
                    ? "form-control error-field"
                    : "form-control"
                }
                value={this.state.subject}
                onChange={(e) => this.setState({ subject: e.target.value })}
              />
              {this.state.error_subject && (
                <span className="error-text">{this.state.error_subject}</span>
              )}
            </div>
            <div class="form-group">
              <label for="">Your message (optional)</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="2"
                class="form-control"
                placeholder="Enter your message heres"
                value={this.state.messageText}
                onChange={(e) => this.setState({ messageText: e.target.value })}
              ></textarea>
            </div>
            <button
              type="submit"
              class="submit-button"
              onClick={() => this.submit()}
            >
              Submit
            </button>
          </div>
        </Spin>
      </div>
    );
  }
}
