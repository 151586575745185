import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <img src="./assets/images/footer-logo.svg" class="footer-logo" alt="" />
        <div className="footer-line"></div>
        <div className="container footer-link-list">
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div
                className="row link-content"
                style={{ maxWidth: 1040, margin: "0 auto" }}
              >
                <div className="col-lg-4">
                  <div className="details contact-links">
                    <span className="link-heading">CONTACT DETAILS</span>
                    <ul>
                      <li className="line">
                        <i className="bx bxs-map"></i>
                        <span>
                          Registered Office: 39, Chitale Marg, Dhantoli, Nagpur
                          (440012)
                        </span>
                      </li>
                      <li className="line">
                        <i className="bx bxs-map"></i>
                        <span>
                          Pune: A1-206, Daffodils Society, Pimple Saudagar, Pune
                          (411027)
                        </span>
                      </li>
                      <li>
                        <i
                          className="bx bx-envelope-open"
                          style={{ top: -1 }}
                        ></i>
                        <a href="mailto:info@probacus.com">info@probacus.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="details social-new-links">
                    <span className="link-heading">SOCIAL NETWORKS</span>
                    <ul>
                      <li className="line">
                        <i className="bx bxl-facebook" style={{ top: 1 }}></i>
                        <span>@probacus</span>
                      </li>
                      <li className="line">
                        <i className="bx bxl-instagram" style={{ top: 1 }}></i>
                        <span>@probacus</span>
                      </li>
                      <li>
                        <i className="bx bxl-linkedin" style={{ top: -1 }}></i>
                        <span>probacus</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="details">
                    <span className="link-heading">LINKS LIST</span>
                    <ul>
                      <li className="line">
                        <span>Consulting</span>
                      </li>
                      <li className="line">
                        <span>Training</span>
                      </li>
                      <li className="line">
                        <span>Automation iot etc.</span>
                      </li>
                      <li>
                        {" "}
                        <span>Products Reselling</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p className="mb-0 text-center">
            Copyright ©2023{" "}
            <a href="/">Probacus Engineering Solutions PVT.LTD.</a> All rights
            reserved.
          </p>
        </div>
      </footer>
    );
  }
}
