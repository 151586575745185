import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactForm from "./components/ContactForm";
import { Helmet } from "react-helmet";

export default class Contact extends Component {
  openEmail = () => {
    window.location.assign("mailto:info@probacus.com");
  };
  render() {
    return (
      <div className="about-page contact-us-page">
        <Helmet>
          <title>Contact us</title>
          <meta
            name="description"
            content="Get in touch with Probacus Engineering Solutions. Reach out for expert consultation in process automation, design, and optimization. Your journey to enhanced efficiency starts here."
          />
          <meta
            name="keywords"
            content="Contact Probacus, Industrial Consultation, Process Automation, Engineering Solutions"
          />
        </Helmet>
        <Header />
        <section class="hero-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="tilt-area">
                  <div class="background-overlay"></div>
                </div>
                <div className="content">
                  <div class="mini-logo">
                    <img src="./assets/images/logo-min.png" />
                    <div id="text-blob">Probacus</div>
                  </div>
                  <div class="row para">
                    <div class="col-lg-12 m-auto">
                      <h4 className="text-center">Contact</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="get-in-touch-sec">
          <div class="container">
            <div class="row m-auto" style={{ maxWidth: 1140 }}>
              <div class="col-lg-6">
                <div class="address-block">
                  <h3>Get In Touch</h3>
                  <div class="address-list">
                    <ul>
                      <li>
                        <div class="icon">
                          <svg
                            aria-hidden="true"
                            class="e-font-icon-svg e-far-building"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"></path>
                          </svg>
                        </div>
                        <div class="address-details">
                          <h4>Main Office</h4>
                          <span>
                            39, Chitale Marg, Dhantoli, Nagpur – 440012
                          </span>
                        </div>
                      </li>
                      <li>
                        <div class="icon">
                          <svg
                            aria-hidden="true"
                            class="e-font-icon-svg e-far-building"
                            viewBox="0 0 448 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"></path>
                          </svg>
                        </div>
                        <div class="address-details">
                          <h4>Office 2</h4>
                          <span>
                            A1-206, Daffodils Society, Pimple Saudagar, Pune
                            411027
                          </span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.openEmail()}
                        >
                          <i class="bx bxs-envelope"></i>
                        </div>
                        <div class="address-details">
                          <h4>E-mail</h4>
                          <span>info@probacus.com</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="right-img d-none d-sm-none d-md-none d-lg-block d-xl-block"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="enquiry-section contact-us-section" id="contact-form">
          <div class="background-overlay"></div>
          {/* <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/zM7C_xah03A?autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allowfullscreen
          ></iframe> */}

          <video
            id="contactMyVideo"
            muted="muted"
            width="100%"
            height="100%"
            autoplay="true"
          >
            <source src="./assets/video/contact-video.mp4" type="video/mp4" />
          </video>

          <div class="container">
            <div class="row">
              <div class="col-lg-7 m-auto">
                <div class="content">
                  <h1>Submit Your Enquiry Here</h1>
                  <p>We will get in touch with you soon!</p>
                  <span>* Details Mandatory</span>
                  <ContactForm classes="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
