export const photos = [
  {
    src: "./assets/images/documents/AVEVA-Certification-Letter_page-0001.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "./assets/images/documents/DLTPL-Completion_page-0001.jpg",
    width: 4,
    height: 3,
  },
];
