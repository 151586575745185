import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from "axios";
import { Spin } from "antd";
import { Helmet } from "react-helmet";

const BASE_URL = "http://admin.probacus.com/";

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverActive: "",
      posts: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.getBlogPosts();
  }
  getBlogPosts = () => {
    this.setState({ loading: true });
    let url = "https://admin.probacus.com/api/blog/get";
    let headers = {
      "Content-Type": "application/json",
    };
    axios
      .get(url, { headers: headers })
      .then((response) => {
        console.log("blog list", response);
        this.setState({
          posts: response.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="about-page">
        <Helmet>
          <title>News</title>
          <meta
            name="description"
            content="Stay updated with the latest news and insights from Probacus Engineering Solutions. Explore industry trends, innovations, and success stories shaping the future of process automation and engineering"
          />
          <meta
            name="keywords"
            content="Industry News, Automation Insights, Engineering Trends, Probacus Updates"
          />
        </Helmet>
        <Header />
        <section class="hero-section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="tilt-area">
                  <div class="background-overlay"></div>
                </div>
                <div className="content">
                  <div class="mini-logo">
                    <img src="./assets/images/logo-min.png" />
                    <div id="text-blob">Probacus</div>
                  </div>
                  <div class="row para">
                    <div class="col-lg-12 m-auto">
                      <h4 className="text-center">News</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="blog-posts">
          <Spin spinning={this.state.loading}>
            <div class="container">
              <div class="row">
                {this.state.posts &&
                  this.state.posts.map((item, index) => {
                    let desc =
                      item.short_desc.length > 100
                        ? item.short_desc.substring(0, 100) + "..."
                        : item.short_desc + "...";
                    let url = item.image_url;
                    return (
                      <div class="col-lg-4" key={index}>
                        <div class="single-post">
                          <a href={`/blog-details?data=${item.blog_id}`}>
                            <div>
                              <div className="img-div">
                                <img src={url} alt="blog img" />
                              </div>
                              <div class="content">
                                <h4>{item.title}</h4>
                                <p>
                                  {desc}
                                  <a
                                    href={`/blog-details?data=${item.blog_id}`}
                                    style={{ color: "#087bc1" }}
                                  >
                                    Read More.
                                  </a>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Spin>
        </section>

        <Footer />
      </div>
    );
  }
}
